.timeline {
    @apply relative flex flex-col md:-space-y-10;

    .event {
        @apply flex flex-col justify-center items-stretch rounded-2xl p-5 relative md:w-80 z-10 top-0;

        &.date {
            @apply ml-5 mb-7.5 md:m-0;

            &:last-child {
                @apply mb-0;
            }
        }

        &.active {
            @apply md:bg-white;

            &.date {
                @apply bg-white;
            }
        }

        &.disabled {
            @apply md:bg-gray-300;

            &.date {
                @apply bg-gray-300;

                & > * {
                    @apply grayscale;
                }
            }

            & > * {
                @apply md:grayscale;
            }
        }
    }

    .event:nth-child(odd) {
        @apply md:object-right md:text-right md:items-end md:right-1/2 md:mr-8;
    }

    .event:nth-child(even) {
        @apply md:object-right md:text-left md:items-start md:left-1/2 md:ml-8;
    }
}
